import MENUITEMS from '../../../app/routers/menuItems';

const APPDATA = {
  menu: [
    {
      name: 'Пользователи',
      icon: 'accessibility',
      url: '/users',
      type: MENUITEMS.USERS,
      selected: false,
      items: [
        {
          name: 'Новые',
          url: '/users/new',
          type: MENUITEMS.NEWUSERS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Ожидающие подтверждение',
          url: '/users/awaiting',
          type: MENUITEMS.AWAITINGUSERS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Подтвержденные',
          url: '/users/confirmed',
          type: MENUITEMS.CONFIRMEDUSERS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отклоненные',
          url: '/users/rejected',
          type: MENUITEMS.REJECTEDUSERS,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'Компании',
      icon: 'business',
      url: '/companies',
      type: MENUITEMS.COMPANIES,
      selected: false,
    },
    {
      name: 'Отпуск B2C',
      icon: 'beach_access',
      url: '/vacation',
      type: MENUITEMS.VACATION,
      selected: false,
    },
    {
      name: 'Сотрудники',
      icon: 'accessibility',
      url: '/employeesPage',
      type: MENUITEMS.EMPLOYEES,
      selected: false,
    },
    {
      name: 'Поездки',
      icon: 'book',
      url: '/order',
      type: MENUITEMS.BOOKTRIP,
      selected: false,
    },
    {
      name: 'Корзины и Блокноты',
      icon: 'search',
      url: '/carts-and-notes',
      type: MENUITEMS.SEARCHUSERS,
      selected: false,
    },
    {
      name: 'Ж/Д билеты',
      icon: 'directions_railway',
      url: '/railway',
      type: MENUITEMS.RAILWAY,
      selected: false,
    },
    {
      name: 'Авиабилеты',
      icon: 'flight',
      url: '/airlineCancel',
      type: MENUITEMS.AIRLINECANCEL,
      selected: false,
    },
    {
      name: 'Такси',
      icon: 'local_taxi',
      url: '/search-taxi',
      type: MENUITEMS.TAXI,
      selected: false,
    },
    {
      name: 'Vip-зал',
      icon: '',
      url: '/vipHallCancel',
      type: MENUITEMS.VIP_HALL_CANCEL,
      selected: false,
    },
    {
      name: 'База отелей',
      icon: 'hotel',
      url: '/hotels',
      type: MENUITEMS.HOTELS,
      selected: false,
      items: [
        {
          name: 'Добавить новый отель',
          url: '/hotels/matchHotels',
          type: MENUITEMS.MATCH_HOTELS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Найти отели',
          url: '/hotels/searchHotels',
          type: MENUITEMS.FIND_HOTELS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Найти регион',
          url: '/regions/searchRegions',
          type: MENUITEMS.FIND_REGIONS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Добавить новый регион',
          url: '/regions/addRegion',
          type: MENUITEMS.ADD_REGION,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'Отчеты',
      icon: 'report',
      url: '/report',
      type: MENUITEMS.REPORT,
      selected: false,
      items: [
        {
          name: 'Заграница',
          url: '/report/aboard',
          type: MENUITEMS.REPORT_ABOARD,
          selected: false,
          disabled: false,
        },
        {
          name: 'Продажи',
          url: '/report/trip',
          type: MENUITEMS.REPORTTRIP,
          selected: false,
          disabled: false,
        },
        {
          name: 'Должники 2',
          url: '/report/daysindebt',
          type: MENUITEMS.REPORTCOMPANIESDAYSINDEBT,
          selected: false,
          disabled: false,
        },
        {
          name: 'Аккаунты',
          url: '/report/accounts',
          type: MENUITEMS.REPORTACCOUNTS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Поступления',
          url: '/report/incoming',
          type: MENUITEMS.REPORTINCOMING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Бронирование',
          url: '/report/booking',
          type: MENUITEMS.REPORTBOOKING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Бронирование 2',
          url: '/report/booking2',
          type: MENUITEMS.REPORTBOOKING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Бронирование 3',
          url: '/report/booking3',
          type: MENUITEMS.REPORTBOOKING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Бронирование WL',
          url: '/report/booking-wl',
          type: MENUITEMS.REPORTBOOKING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Бронирование спецсчет',
          url: '/report/bookingSpecialBilling',
          type: MENUITEMS.REPORTBOOKING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отели',
          url: '/report/hotels',
          type: MENUITEMS.REPORTHOTELS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Оплаты ПК',
          url: '/report/hotels-pk',
          type: MENUITEMS.REPORTHOTELSPK,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет 1',
          url: '/report/report1',
          type: MENUITEMS.REPORT1,
          selected: false,
          disabled: false,
        },
        {
          name: 'Возвраты Oneliya',
          url: '/report/refundsOneliya',
          type: MENUITEMS.REFUNDS_ONELIYA,
          selected: false,
          disabled: false,
        },
        // {
        //   name: 'Полученная выручка',
        //   url: '/report/proceeds',
        //   type: MENUITEMS.REPORTPROCEEDS,
        //   selected: false,
        //   disabled: false,
        // },
        {
          name: 'Действия менеджеров',
          url: '/report/managers-actions',
          type: MENUITEMS.REPORTMANAGERSACTIONS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Овердрафт',
          url: '/report/overdraft',
          type: MENUITEMS.OVERDRAFT,
          selected: false,
          disabled: false,
        },
        {
          name: 'Овердрафт 2',
          url: '/report/overdraft2',
          type: MENUITEMS.OVERDRAFT_SEC,
          selected: false,
          disabled: false,
        },
        {
          name: 'Новые пользователи',
          url: '/report/analytics-amo',
          type: MENUITEMS.ANALYTICSAMO,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет Страхование',
          url: '/report/insurance',
          type: MENUITEMS.INSURANCE,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет Страхование 2',
          url: '/report/insurance2',
          type: MENUITEMS.INSURANCE_SECOND,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет Страхование 3',
          url: '/report/insurance3',
          type: MENUITEMS.INSURANCE_THIRD,
          selected: false,
          disabled: false,
        },
        {
          name: 'Сверка со страховой',
          url: '/report/reconciliation-with-insurance',
          type: MENUITEMS.REINSURANCE,
          selected: false,
          disabled: false,
        },
        {
          name: 'Документ на печатную отправку',
          url: '/report/print-documents',
          type: MENUITEMS.PRINTDOCUMENTS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчёт о бронировании авиа',
          url: '/report/aviaReport',
          type: MENUITEMS.REPORTBOOKING,
          selected: false,
          disabled: false,
        },
        // {
        //   name: 'Отчет Для сбора закрывающих',
        //   url: '/report/supplier-documents',
        //   type: MENUITEMS.SUPPLIERDOCUMENTS,
        //   selected: false,
        //   disabled: false,
        // },
        {
          name: 'Отчет по дате поездки',
          url: '/report/trip-by-date',
          type: MENUITEMS.TRIPBYDATE,
          selected: false,
          disabled: false,
        },
        {
          name: 'Прозвон апартаментов',
          url: '/report/call-apartments',
          type: MENUITEMS.CALL_APARTMENTS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет по такси',
          url: '/bookKeeping/report-taxi',
          type: MENUITEMS.REPOST_TAXI,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет АА vs Поставщики',
          url: '/report/report-providers',
          type: MENUITEMS.REPORTS_PROVIDERS,
          selected: false,
          disabled: false,
        },
        {
          name: 'WL Русгидро',
          url: '/report/report-rushydro',
          type: MENUITEMS.REPORTS_RUSHYDRO,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет по aanda',
          url: '/report/report-aanda',
          type: MENUITEMS.REPORTS_AANDA,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет MICE',
          url: '/report/report-mice',
          type: MENUITEMS.REPORTS_MICE,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'Сервис',
      icon: 'notifications_active',
      url: '/service',
      type: MENUITEMS.SERVICE,
      selected: false,
      items: [
        {
          name: 'Тип отправки',
          url: '/report/sendingtypes-report',
          type: MENUITEMS.REPORTSENDINGTYPE,
          selected: false,
          disabled: false,
        },
        {
          name: 'Чат Стилистика',
          url: '/report/style-report',
          type: MENUITEMS.REPORTSTYLE,
          selected: false,
          disabled: false,
        },
        {
          name: 'Чат Метки',
          url: '/report/tags-report',
          type: MENUITEMS.REPORTTAGS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Чат KPI Сотрудника',
          url: '/report/reply-report',
          type: MENUITEMS.REPORTREPLY,
          selected: false,
          disabled: false,
        },
        {
          name: 'Чат KPI PO',
          url: '/report/kpipo-report',
          type: MENUITEMS.REPORTKPIPO,
          selected: false,
          disabled: false,
        },
        {
          name: 'Чат Стоп Слово',
          url: '/report/stopword-report',
          type: MENUITEMS.REPORTSTOPWORD,
          selected: false,
          disabled: false,
        },
        {
          name: 'KPI Консультации',
          url: '/report/consultations-report',
          type: MENUITEMS.REPORTCONSULTATIONS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Обход KPI',
          url: '/report/kpinote-report',
          type: MENUITEMS.REPORTKPINOTE,
          selected: false,
          disabled: false,
        },
        {
          name: 'Время обработки чатов',
          url: '/report/timechat-report',
          type: MENUITEMS.REPORTTIMECHAT,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'Бухгалтерия',
      icon: 'assignment',
      url: '/bookKeeping',
      type: MENUITEMS.BOOKKEEPING,
      selected: false,
      items: [
        {
          name: 'Выгрузка в нашу 1С',
          url: '/bookKeeping/unloading1c',
          type: MENUITEMS.UNLOADING1C,
          selected: false,
          disabled: false,
        },
        {
          name: 'Подготовить документы для распечатки',
          url: '/report/print',
          type: MENUITEMS.REPORTPRINT,
          selected: false,
          disabled: true,
        },
        // {
        //   name: 'Проблемные акты сверок',
        //   url: '/report/reviseacttest',
        //   type: MENUITEMS.REVISEACTTEST,
        //   selected: false,
        //   disabled: false,
        // },
        {
          name: 'Отчет по схемам',
          url: '/report/schemas',
          type: MENUITEMS.REPORTSCHEMAS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Автоматическая отправка ЭДО Диадок',
          url: '/report/mailing',
          type: MENUITEMS.AUTOSENDINGEDO,
          selected: false,
          disabled: false,
        },
        {
          name: 'Общий акт выполненных работ',
          url: '/report/generalAct',
          type: MENUITEMS.GENERAL_ACT_COMPLETED_WORKS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отправка в Диадок',
          url: '/bookKeeping/sendingDiadoc',
          type: MENUITEMS.SENDINGDIADOC,
          selected: false,
          disabled: false,
        },
        {
          name: 'Требует исправления',
          url: '/packages/correction',
          type: MENUITEMS.PACKAGESCORRECTION,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет по редактированию закрывающих',
          url: '/packages/editingclosing-report',
          type: MENUITEMS.REPORTEDITINGCLOSING,
          selected: false,
          disabled: false,
        },
        {
          name: 'Поступления и списания по картам',
          url: '/report/paymentCard',
          type: MENUITEMS.PAYMENT_CARD,
          selected: false,
          disabled: false,
        },
        {
          name: 'Списания и пополнения КОНТУР, ППР',
          url: '/report/monetaryTransactions',
          type: MENUITEMS.PPR_AND_KONTUR,
          selected: false,
          disabled: false,
        },
        {
          name: 'Оплата отелей ПК',
          url: '/bookKeeping/payment-hotels',
          type: MENUITEMS.PAYMENT_HOTELS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Заказы отелей, требующие внесения НДС',
          url: '/report/hotel-vat',
          type: MENUITEMS.HOTEL_VAT,
          selected: false,
          disabled: false,
        },
        {
          name: 'Заказы отелей с ошибками от A&A',
          url: '/report/aa-vat',
          type: MENUITEMS.AA_VAT,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'АТС',
      icon: 'notifications_active',
      url: '/atc',
      type: MENUITEMS.ATC,
      selected: false,
      items: [
        {
          name: 'Звонки',
          url: '/atc/calls',
          type: MENUITEMS.CALLS,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'AMOCRM',
      icon: 'account_balance_wallet',
      url: '/amocrm',
      type: MENUITEMS.AMOCRM,
      selected: false,
      items: [
        {
          name: 'Загрузка контактов',
          url: '/amocrm/uploading',
          type: MENUITEMS.UPLOADINGCONTACTS,
          selected: false,
          disabled: false,
        },
        {
          name: 'Отчет Amo',
          url: '/amocrm/amorept',
          type: MENUITEMS.AMOREPT,
          selected: false,
          disabled: false,
        },
        {
          name: 'Настройка воронок',
          url: '/amocrm/settingFunnels',
          type: MENUITEMS.SETTING_FUNNELS,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'Настройки сервиса',
      icon: 'settings',
      url: '/settings',
      type: MENUITEMS.SETTINGS,
      selected: false,
    },
    {
      name: 'Калькулятор',
      icon: 'exposure',
      url: '/calculator',
      type: MENUITEMS.CALCULATOR,
      selected: false,
    },
    {
      name: 'Автоматическая отправка писем',
      icon: 'email',
      url: '/autoSendingEmail',
      type: MENUITEMS.AUTO_SENDING_EMAILS,
      selected: false,
    },
    {
      name: 'MICE',
      icon: 'assignment',
      url: '/mice',
      type: MENUITEMS.MICE,
      selected: false,
      items: [
        {
          name: 'Отчет по Alvist',
          url: '/mice/alvistReport',
          type: MENUITEMS.ALVIST,
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'Клиентская сессия',
      icon: 'accessibility',
      url: '/clientSession',
      type: MENUITEMS.CLIENT_SESSION,
      selected: false,
    },
    {
      name: 'Режим разработки',
      icon: 'code',
      url: '/v2/dev-page',
      type: MENUITEMS.DEV_PAGE,
      selected: false,
    },
  ],
};

export default APPDATA;
