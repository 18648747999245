import { preparedRatesWithNoRate } from '../../utils/account';
import { momentObject } from '../../utils/formatDate';
import {
  ACCOUNTVALUE,
  BRAND_NAME,
  NONERATE,
  RATES,
  REGULARITY_PAY,
  TYPERATRATES,
  DEFAULT_CURRENCY_INFO,
} from '../../constants/account';

let ACTIONS;

const defaultPlanFeeSettings = () => ({
  rates: preparedRatesWithNoRate(),
  currentRate: NONERATE,
  updatedRate: '',
  company: 'Юрлицо для списания',
  companyId: 0,
  regularityPay: REGULARITY_PAY[0].value,
  dateUpdatedPlanfee: '',
});

const DEFAULT = {
  account: {
    Id: null,
    Name: '',
    Comment: '',
    IntercomComment: '',
    CreateDate: '',
    SoldBy: '',
    SupportedBy: '',
    AccountType: ACCOUNTVALUE.COMMON,
    Test: true,
    Rates: {
      Airline: {
        Order: '',
        Change: '',
        SUDiscount: true,
        SummationFees: true,
      },
      Train: {
        Order: '',
        Cancel: '',
      },
      Hotel: '',
      Hotels3d: [],
    },
    DisableAccountInvoice: false,
    Companies: [],
    Employees: [],
    Enabled: true,
    PlanFeeSettings: [],
    AccountSettings: {},
    FeeDate: '',
    companiesGroup: {
      GroupName: '',
      Accounts: [],
    },
  },
  company: { HasDebt: false },
  specialAccount: {
    AccountId: 0,
    BillNumber: '',
    AviacentrLogin: '',
    AviacentrPassword: '',
  },
  subAccounts: [],
  CompanieForLimits: [],
  planFeeSettings: defaultPlanFeeSettings(),
  accountSettings: {},
  showPlanFeeDialog: false,
  showAccountSettingsDialog: false,
  showEstablishedLimitsDialog: false,
  accountSettingsFlag: true,
  amoId: 0,
  loadAmoId: false,
  aggregatorId: 0,
  loadingAggregation: true,
  loadingAccountSettings: true,
  loadingLimits: true,
  loadingAccountDisabled: true,
  loadingHotelAggregation: true,
  hotelAggregation: {
    AccountId: null,
    HideHotels: [],
    HotelTypes: [],
    ExceptHotels: [],
  },
  listHideHotelTypes: [],
  isSmartAgent: false,
  isSmartwayKZ: false,
  isSmartAgentAuto: false,
  currencyInfo: DEFAULT_CURRENCY_INFO,
  leadType: '',
  airlineProviderScheme: null,
};

const preparedPlanFeeSettings = (rates, rate) => {
  const preparedRate = rates;

  if (!RATES.includes(rate)) {
    const updateRate = {
      value: rate,
      label: `${rate}`,
      id: `${TYPERATRATES.ORIGINAL}_${rate}`,
      type: TYPERATRATES.ORIGINAL,
    };
    preparedRate.splice(rates.length - 1, 0, updateRate);
  }

  const currentRate = preparedRate.find(({ value, id }) => id !== TYPERATRATES.DEFAULT && value === rate);

  return {
    rates: preparedRate,
    currentRate,
  };
};

const mapperPlanFeeSettings = (PlanFeeSettings, planFeeSettings, Companies) => PlanFeeSettings.map(({
  CompanyId, Rate, Type, UpdatedRate, DateUpdatedPlanFee,
}) => {
  const { CompanyName, ShortCompanyName } = Companies.find((i) => i.CompanyId === CompanyId);
  const { rates, currentRate } = preparedPlanFeeSettings(planFeeSettings.rates, Rate);

  return {
    companyId: CompanyId,
    rates,
    currentRate,
    updatedRate: UpdatedRate || '',
    company: ShortCompanyName || CompanyName,
    regularityPay: Type,
    dateUpdatedPlanfee: DateUpdatedPlanFee ? momentObject(DateUpdatedPlanFee) : '',
  };
});

const reducer = (action, state) => {
  const newState = { ...state };

  switch (action.type) {
    case ACTIONS.ACCOUNT.LOADACCOUNT: {
      const {
        account,
        company,
        specialBillData,
        account: { companiesGroup },
      } = action;
      const disableAccountInvoice = account.DisableAccountInvoice ? ACCOUNTVALUE.OFF : ACCOUNTVALUE.ON;

      account.Companies = account.Companies.map((item) => {
        const contract = item.Contract || { Number: '', StartDate: '' };

        return {
          ...item,
          Contract: contract,
        };
      });

      newState.planFeeSettings = defaultPlanFeeSettings();

      newState.account = {
        ...account,
        AccountSettings: newState.accountSettings,
        AccountType: account.AccountType,
        DisableAccountInvoice: disableAccountInvoice,
        companiesGroup: companiesGroup || DEFAULT.account.companiesGroup,
      };

      if (company) {
        newState.company = company;
      }

      if (specialBillData) {
        newState.specialAccount = specialBillData;
      }

      const { PlanFeeSettings, Companies } = newState.account;
      const { planFeeSettings } = newState;

      if (PlanFeeSettings.length) {
        newState.planFeeSettings = mapperPlanFeeSettings(PlanFeeSettings, planFeeSettings, Companies);
      } else {
        newState.planFeeSettings = [defaultPlanFeeSettings()];
      }

      break;
    }

    case ACTIONS.ACCOUNT.LOAD_ACCOUNT_FOR_ESTABLISHED_LIMITS: {
      const { account: { Companies } } = action;
      newState.CompanieForLimits = Companies;

      break;
    }

    case ACTIONS.ACCOUNT.LOAD_SPECIAL_BILL: {
      const { specialBillData } = action;
      newState.specialAccount = specialBillData;

      break;
    }

    case ACTIONS.ACCOUNT.LOAD_COMPANIES_GROUP: {
      const { Accounts } = action;
      newState.account.companiesGroup.Accounts = Accounts;

      break;
    }

    case ACTIONS.ACCOUNT.UPDATERATES: {
      newState.account.Rates[action.rateType] = action.value;
      break;
    }

    case ACTIONS.ACCOUNT.UPDATECOMPANIES: {
      const { company } = action;

      if (!company.Contract) {
        company.Contract = { Number: '', StartDate: '' };
      }
      newState.account.Companies.push(company);
      break;
    }

    case ACTIONS.ACCOUNT.LOADCOMPANYFUNDS: {
      return {
        ...state,
        company: {
          ...state.company,
          HasDebt: action.funds.HasDebt,
        },
      };
    }

    case ACTIONS.ACCOUNT.COMPANIES_IN_ACCOUNT: {
      return {
        ...state,
        subAccounts: action.subAccounts,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_LOADING_ACCOUNT_SETTINGS: {
      return {
        ...state,
        loadingAccountSettings: action.loading,
      };
    }

    case ACTIONS.ACCOUNT.LOADING_LIMITS: {
      return {
        ...state,
        loadingLimits: action.loading,
      };
    }

    case ACTIONS.ACCOUNT.ACCOUNT_DISABLED: {
      return {
        ...state,
        loadingAccountDisabled: !state.loadingAccountDisabled,
      };
    }

    case ACTIONS.ACCOUNT.LOAD_ACCOUNT_SETTINGS: {
      return {
        ...state,
        accountSettings: action.accountSettings,
        account: {
          ...state.account,
          AccountSettings: action.accountSettings,
        },
      };
    }

    case ACTIONS.ACCOUNT.UPDATEPLANFEESETTINGS: {
      const foundIndex = state.planFeeSettings.rates.findIndex(({ id }) => id === action.payload.currentRate.id);
      const { planFeeSettings } = state;

      planFeeSettings.rates[foundIndex] = action.payload.currentRate;
      planFeeSettings.currentRate = action.payload.currentRate;

      return { ...state };
    }

    case ACTIONS.ACCOUNT.GET_AMO_ID: {
      return {
        ...state,
        amoId: action.amoId,
        loadAmoId: action.loadAmoId,
      };
    }

    case ACTIONS.ACCOUNT.SHOW_PLAN_FEE_SETTING_DIALOG: {
      return {
        ...state,
        showPlanFeeDialog: !state.showPlanFeeDialog,
      };
    }

    case ACTIONS.ACCOUNT.SHOW_ACCOUNT_SETTINGS_DIALOG: {
      return {
        ...state,
        showAccountSettingsDialog: !state.showAccountSettingsDialog,
      };
    }

    case ACTIONS.ACCOUNT.SHOW_ESTABLISHED_LIMITS_DIALOG: {
      return {
        ...state,
        showEstablishedLimitsDialog: !state.showEstablishedLimitsDialog,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_PLAN_FEE_SETTINGS: {
      return {
        ...state,
        planFeeSettings: action.planFeeSettings,
      };
    }

    case ACTIONS.ACCOUNT.ADD_PLAN_FEE_SETTING_ENTITY: {
      return {
        ...state,
        planFeeSettings: [...state.planFeeSettings, defaultPlanFeeSettings()],
      };
    }

    case ACTIONS.ACCOUNT.CLOSE_PLAN_FEE_SETTINGS: {
      const { account } = state;
      const { PlanFeeSettings, Companies } = account;

      return {
        ...state,
        planFeeSettings: PlanFeeSettings.length
          ? mapperPlanFeeSettings(PlanFeeSettings, defaultPlanFeeSettings(), Companies)
          : [defaultPlanFeeSettings()],
      };
    }

    case ACTIONS.ACCOUNT.CLOSE_ACCOUNT_SETTINGS: {
      const { account } = state;
      const { AccountSettings } = account;

      return {
        ...state,
        accountSettings: AccountSettings,
      };
    }

    case ACTIONS.ACCOUNT.REMOVE_PLAN_FEE_SETTINGS: {
      const { index } = action;

      return {
        ...state,
        planFeeSettings: [
          ...state.planFeeSettings.slice(0, index),
          ...state.planFeeSettings.slice(index + 1),
        ],
      };
    }

    case ACTIONS.ACCOUNT.LOAD_AGGREGATION: {
      return {
        ...state,
        loadingAggregation: action.payload,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_AGGREGATION: {
      return {
        ...state,
        aggregatorId: action.payload,
        loadingAggregation: false,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_HOTEL_AGGREGATION: {
      return {
        ...state,
        hotelAggregation: action.payload.hotelAggregation,
        listHideHotelTypes: action.payload.listHideHotelTypes,
        loadingHotelAggregation: false,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_HIDE_TYPES_HOTEL_AGGREGATION: {
      return {
        ...state,
        hotelAggregation: {
          ...state.hotelAggregation,
          HotelTypes: action.payload,
        },
      };
    }

    case ACTIONS.ACCOUNT.ADD_HIDE_HOTELS: {
      return {
        ...state,
        hotelAggregation: {
          ...state.hotelAggregation,
          HideHotels: [...state.hotelAggregation.HideHotels, action.payload],
        },
      };
    }

    case ACTIONS.ACCOUNT.ADD_HOTELS: {
      return {
        ...state,
        hotelAggregation: {
          ...state.hotelAggregation,
          [action.payload.field]: [...state.hotelAggregation[action.payload.field], action.payload.value],
        },
      };
    }

    case ACTIONS.ACCOUNT.DELETE_HOTELS: {
      const { field, id } = action.payload;

      return {
        ...state,
        hotelAggregation: {
          ...state.hotelAggregation,
          [field]: state.hotelAggregation[field].filter(({ Id }) => Id !== id),
        },
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_LOADING_HOTEL_AGGREGATION: {
      return {
        ...state,
        loadingHotelAggregation: action.payload,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_SMART_AGENT: {
      return {
        ...state,
        isSmartAgent: action.payload,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_SMARTWAY_KZ: {
      return {
        ...state,
        isSmartwayKZ: action.payload.BrandName === BRAND_NAME.SMARTWAY_KZ || false,
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_SMART_AGENT_AUTO: {
      return {
        ...state,
        isSmartAgentAuto: action.payload,
      };
    }

    case ACTIONS.ACCOUNT.CHANGE_EXCHANGE_CURRENCY: {
      const {
        Name,
        Value,
        ValueWithMultiplier,
        Multiplier,
        MultiplierPercent,
        DateUTC,
      } = action.payload;

      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          CurrencyName: Name,
          CurrencyValue: Value,
          CurrencyMultiplierPercent: MultiplierPercent,
          CurrencyValueWithMultiplier: ValueWithMultiplier,
          DateUTC,
          Multiplier,
        },
      };
    }

    case ACTIONS.ACCOUNT.CHANGE_CURRENCY_INFO: {
      const { field, value } = action.payload;

      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          [field]: value,
        },
      };
    }

    case ACTIONS.ACCOUNT.CHANGE_PRICES_CURRENCY: {
      const { Price: { Base, Total }, Penalties } = action.payload;

      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          Base,
          Total,
          Penalties: Penalties.map(({
            From,
            ProviderPrice,
            Total: TotalCurrency,
            Base: BaseCurrency,
          }) => ({
            From: momentObject(From),
            ProviderPrice,
            Total: TotalCurrency,
            Base: BaseCurrency,
          })),
        },
      };
    }

    case ACTIONS.ACCOUNT.ADD_PENALTIES_CURRENCY: {
      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          Penalties: [
            ...state.currencyInfo.Penalties,
            {
              From: '', ProviderPrice: 0, Total: 0, Base: 0,
            },
          ],
        },
      };
    }

    case ACTIONS.ACCOUNT.UPDATE_PENALTIES_CURRENCY: {
      const {
        idx, field, value,
      } = action.payload;

      const newPenalties = state.currencyInfo.Penalties.map((item, index) => {
        if (idx === index) {
          const penalty = item;
          penalty[field] = value;

          return penalty;
        }

        return item;
      });

      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          Penalties: newPenalties,
        },
      };
    }

    case ACTIONS.ACCOUNT.REMOVE_PENALTY_CURRENCY: {
      const { idx } = action.payload;

      const newPenalties = state.currencyInfo.Penalties.filter((item, index) => index !== idx);

      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          Penalties: newPenalties,
        },
      };
    }

    case ACTIONS.ACCOUNT.RESET_FORM_CURRENCY: {
      return {
        ...state,
        currencyInfo: DEFAULT_CURRENCY_INFO,
      };
    }

    case ACTIONS.ACCOUNT.CHANGE_LEAD_TYPE: {
      return {
        ...state,
        leadType: action.payload,
      };
    }

    case ACTIONS.ACCOUNT.SET_AIRLINE_AGGREGATION: {
      return {
        ...state,
        airlineProviderScheme: { aeroflot: action.payload },
      };
    }
  }

  return newState;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');

  core.addStore('Account', reducer, DEFAULT);
}
