let ACTIONS;

const LABELS_ERROR = {
  STATUS_NOT_FOUND: 'Файл не найден',
  FILE_UPLOAD_FAILED: 'Не удалось загрузить файл',
  PROCESS_AMO_REPORT_FAILED: 'Не удалось обработать файл',
};

const DEFAULT = {
  statusId: null,
  uploaded: false,
  failed: false,
  processing: false,
  error: '',
  tag: '',
  guid: '',
  contacts: [],
  isLoading: true,
  targetFunnel: [],
  rules: [],
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.AMOCRM.PROCESSAMOREPORT: {
      return {
        ...state,
        failed: true,
        error: LABELS_ERROR.PROCESS_AMO_REPORT_FAILED,
      };
    }

    case ACTIONS.AMOCRM.UPLOADCONTACTS: {
      const { id: statusId } = action.payload;

      return {
        ...state,
        uploaded: true,
        processing: true,
        failed: false,
        statusId,
        tag: '',
      };
    }

    case ACTIONS.AMOCRM.UPLOADCONTACTSFAIL: {
      return {
        ...state,
        uploaded: false,
        failed: true,
        processing: false,
        error: LABELS_ERROR.FILE_UPLOAD_FAILED,
        statusId: null,
      };
    }

    case ACTIONS.AMOCRM.RESETERROR: {
      return {
        ...state,
        failed: false,
        error: '',
      };
    }

    case ACTIONS.AMOCRM.UPLOADFILE: {
      return {
        ...state,
        uploaded: true,
        failed: false,
        guid: action.payload,
      };
    }

    case ACTIONS.AMOCRM.UPLOADFILEFAIL: {
      return {
        ...state,
        uploaded: false,
        failed: true,
        error: LABELS_ERROR.FILE_UPLOAD_FAILED,
      };
    }

    case ACTIONS.AMOCRM.RESET: {
      return DEFAULT;
    }

    case ACTIONS.AMOCRM.PROCESSING_UPDATE_CONTACTS: {
      return {
        ...state,
        contacts: action.payload,
        processing: true,
        failed: false,
      };
    }

    case ACTIONS.AMOCRM.UPDATE_CONTACTS: {
      return {
        ...state,
        contacts: action.payload,
        processing: false,
        failed: false,
      };
    }

    case ACTIONS.AMOCRM.NO_UPDATE_CONTACTS: {
      return {
        ...state,
        contacts: action.payload,
        failed: true,
        error: LABELS_ERROR.STATUS_NOT_FOUND,
        statusId: null,
        processing: true,
      };
    }

    case ACTIONS.AMOCRM.NO_DOWNLOAD_CONTACTS: {
      return {
        ...state,
        failed: true,
        error: LABELS_ERROR.FILE_UPLOAD_FAILED,
      };
    }

    case ACTIONS.AMOCRM.LOADING_CONTACTS: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ACTIONS.AMOCRM.TARGET_FUNNEL: {
      return {
        ...state,
        targetFunnel: action.payload,
      };
    }

    case ACTIONS.AMOCRM.DUPLICATE_FUNNEL: {
      return {
        ...state,
        rules: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('AmoCRM', reducer, DEFAULT);
}
